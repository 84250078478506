import { createRouter, createWebHistory } from "@ionic/vue-router";
import { RouteRecordRaw } from "vue-router";
import { Store } from "@/store";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    redirect: "/dashboard",
  },
  {
    path: "/dashboard",
    component: () => import("../views/DashboardPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/error/:errorCode",
    component: () => import("../views/ErrorPage.vue"),
    meta: {
      disableIfAuth: false,
      requiresAuth: false,
    },
  },
  {
    path: "/finish/:actionName",
    component: () => import("../views/FinishPage.vue"),
    meta: {
      requiresAuth: true,
      requiresMobile: false,
    },
  },
  {
    path: "/login",
    component: () => import("../views/LoginPage.vue"),
    meta: {
      disableIfAuth: true,
      requiresMobile: false,
    },
  },
  {
    path: "/logout",
    component: () => import("../views/LogoutPage.vue"),
    meta: {
      requiresAuth: true,
      requiresMobile: false,
    },
  },
  {
    path: "/play",
    component: () => import("../views/PlayPage.vue"),
    meta: {
      requiresAuth: true,
      requiresMobile: false,
    },
  },
  {
    path: "/ranking",
    component: () => import("../views/RankingPage.vue"),
    meta: {
      requiresAuth: false,
      requiresMobile: false,
    },
  },
  {
    path: "/reset",
    component: () => import("../views/ResetPage.vue"),
    meta: {
      requiresAuth: true,
      requiresMobile: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requiresMobile) &&
    !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    )
  ) {
    router.push("/error/ERR999");
    return;
  }

  if (
    to.matched.some((record) => record.meta.disableIfAuth) &&
    Store.getters.isLogged
  ) {
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.getters.isLogged) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
