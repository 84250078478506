import axios from "axios";
import qs from "query-string";
import router from "@/router";
import Vuex from "vuex";

import { alertComponent } from "@/helper";

type Theme = {
  _id: string;
  defalt: boolean;
  label: string;
};

export const Store = new Vuex.Store({
  state: {
    config: {
      server: "https://api.sm.gbrl.cloud",
    },
    err: null,
    life: <any>{},
    loggingin: false,
    nickname: <string>"",
    openNicknameModal: <boolean>false,
    play: {
      currentLevel: <number>0,
      currentQuestion: {},
      dateFinish: <number>0,
      dateInit: <number>0,
      dateLastResponse: null,
      helpers: {
        cards: <number>1,
        jumps: <number>3,
      },
      sessionId: <string>"",
      timeRemaining: <number>30,
    },
    ranking: [],
    theme: <string>"",
    themes: <Theme[]>[],
    token: <string>"",
    username: <string>"",
  },
  actions: {
    changeNicknameModal: (ctx, payload = {}) => {
      const { action } = payload;

      switch (action) {
        case "close":
          ctx.state.openNicknameModal = false;
          break;

        case "open":
          ctx.state.openNicknameModal = true;
          break;

        default:
          ctx.state.openNicknameModal = !ctx.state.openNicknameModal;
          break;
      }
    },

    setNickname: async (ctx, payload = {}) => {
      const instance = axios.create();
      const nickname = payload.nickname || null;

      if (
        nickname == null ||
        (typeof nickname == "string" && nickname.length == 0)
      ) {
        alertComponent({
          header: "🙄 ATENÇÃO!",
          message: "O apelido não pode ser nulo.",
        });
        return false;
      }

      await instance
        .post(
          ctx.state.config.server + `/game/v1/setNickname`,
          new URLSearchParams({
            nickname,
            username: ctx.state.username,
          }).toString(),
          {
            headers: {
              Authorization: `Bearer ${ctx.state.token}`,
            },
          }
        )
        .then((_) => {
          if (_.data) ctx.state.nickname = nickname;
        })
        .catch((err) => {
          if (err.response && err.response.status == 403) {
            router.push("/login");
            return;
          }
        });

      return ctx.state.nickname == nickname;
    },

    setTheme: (ctx, payload) => {
      const { theme } = payload;

      if (ctx.state.theme !== theme) {
        ctx.state.theme = theme;
        Store.commit("getLife", { theme: theme });
      }

      return;
    },

    upLevel: (ctx) => {
      const nextLevel = ctx.state.play.currentLevel + 1;

      if (ctx.state.play.currentLevel == 0 && ctx.state.theme) {
        Store.commit("getLife", { theme: ctx.state.theme });

        if (!ctx.state.life[ctx.state.theme].currentLife) {
          alertComponent({
            header: "😥 DESCULPE!",
            message: "Não há vidas suficientes para continuar.",
          });
          return false;
        }
      }

      if (nextLevel > 16) {
        router.push("/finish/success");
        return false;
      }

      ctx.state.play.currentLevel = nextLevel;
      ctx.state.play.currentQuestion = {};

      return true;
    },

    useCards: (ctx) => {
      if (ctx.state.play.helpers.cards > 0) {
        ctx.state.play.helpers.cards -= 1;
        return true;
      }

      return false;
    },

    useJump: (ctx) => {
      if (ctx.state.play.helpers.jumps > 0) {
        ctx.state.play.helpers.jumps -= 1;
        return true;
      }

      return false;
    },
  },
  getters: {
    isLogged: (state) => state.token.length > 0 && state.username.length > 0,
    isLoggingin: (state) => state.loggingin || false,
    isOpenNicknameModal: (state) => state.openNicknameModal || false,
    getCurrentCards: (state) => state.play.helpers.cards || 0,
    getCurrentJumps: (state) => state.play.helpers.jumps || 0,
    getCurrentLevel: (state) => state.play.currentLevel || 0,
    getCurrentLife: (state) =>
      state.theme ? state.life[<any>state.theme] : [],
    getCurrentNickname: (state) => state.nickname || "",
    getCurrentQuestion: (state) => state.play.currentQuestion || {},
    getCurrentRanking: (state) => state.ranking || [],
    getCurrentTheme: (state) => state.theme || "",
    getCurrentValues: (state) => {
      switch (state.play.currentLevel || 0) {
        case 1:
          return {
            error: { text: "NADA", value: 0 },
            stop: { text: "NADA", value: 0 },
            win: { text: "1 MIL", value: 1000 },
          };

        case 2:
          return {
            error: { text: "500", value: 500 },
            stop: { text: "1 MIL", value: 1000 },
            win: { text: "2 MIL", value: 2000 },
          };

        case 3:
          return {
            error: { text: "1 MIL", value: 1000 },
            stop: { text: "2 MIL", value: 2000 },
            win: { text: "3 MIL", value: 3000 },
          };

        case 4:
          return {
            error: { text: "1,5 MIL", value: 1500 },
            stop: { text: "3 MIL", value: 3000 },
            win: { text: "4 MIL", value: 4000 },
          };

        case 5:
          return {
            error: { text: "2 MIL", value: 2000 },
            stop: { text: "4 MIL", value: 4000 },
            win: { text: "5 MIL", value: 5000 },
          };

        case 6:
          return {
            error: { text: "2,5 MIL", value: 2500 },
            stop: { text: "5 MIL", value: 5000 },
            win: { text: "10 MIL", value: 10000 },
          };

        case 7:
          return {
            error: { text: "5 MIL", value: 5000 },
            stop: { text: "10 MIL", value: 10000 },
            win: { text: "20 MIL", value: 20000 },
          };

        case 8:
          return {
            error: { text: "10 MIL", value: 10000 },
            stop: { text: "20 MIL", value: 20000 },
            win: { text: "30 MIL", value: 30000 },
          };

        case 9:
          return {
            error: { text: "15 MIL", value: 15000 },
            stop: { text: "30 MIL", value: 30000 },
            win: { text: "40 MIL", value: 40000 },
          };

        case 10:
          return {
            error: { text: "20 MIL", value: 20000 },
            stop: { text: "40 MIL", value: 40000 },
            win: { text: "50 MIL", value: 50000 },
          };

        case 11:
          return {
            error: { text: "25 MIL", value: 25000 },
            stop: { text: "50 MIL", value: 50000 },
            win: { text: "100 MIL", value: 100000 },
          };

        case 12:
          return {
            error: { text: "50 MIL", value: 50000 },
            stop: { text: "100 MIL", value: 100000 },
            win: { text: "200 MIL", value: 200000 },
          };

        case 13:
          return {
            error: { text: "100 MIL", value: 100000 },
            stop: { text: "200 MIL", value: 200000 },
            win: { text: "300 MIL", value: 300000 },
          };

        case 14:
          return {
            error: { text: "150 MIL", value: 150000 },
            stop: { text: "300 MIL", value: 300000 },
            win: { text: "400 MIL", value: 400000 },
          };

        case 15:
          return {
            error: { text: "200 MIL", value: 200000 },
            stop: { text: "400 MIL", value: 400000 },
            win: { text: "500 MIL", value: 500000 },
          };

        case 16:
          return {
            error: { text: "PERDE TUDO", value: 0 },
            stop: { text: "500 MIL", value: 500000 },
            win: { text: "1 MILHÃO", value: 1000000 },
          };

        default:
          return {
            error: { text: "NADA", value: 0 },
            stop: { text: "NADA", value: 0 },
            win: { text: "NADA" },
          };
      }
    },
    getError: (state) => state.err || "",
    getThemes: (state) => state.themes || [],
    getTimeRemaining: (state) => state.play.timeRemaining || 0,
    getUsername: (state) => (state.username ? state.username : "Faça login."),
  },
  mutations: {
    getLife: async function (state, payload) {
      const instance = axios.create();
      const { theme } = payload;
      let query = {};

      if (typeof theme != "undefined" && theme)
        query = { theme, username: state.username };

      await instance
        .post(state.config.server + `/game/v1/getLife`, qs.stringify(query), {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .then(async (_) => {
          if (_.status == 403) {
            router.push("/login");
            return;
          }

          if (_.data) {
            const keyLife = theme as keyof typeof state.life;

            if (typeof theme != "undefined") {
              /*if (_.data.deaths.length) {

                            const death = _.data.deaths[0];

                            _.data.renewTimer = {
                                
                                'timer': setInterval(() => {

                                    const now = new Date();
                                    const timeRemaining = Math.round((death - now.getTime())/1000);
                            
                                    if (timeRemaining < 0) {
                            
                                        clearInterval(state.life.renewTimer.timer);
                                        state.renewTimer.value = 0;
                                    
                                    }

                                    Object.assign(state.life.renewTimer, {

                                        'value': timeRemaining

                                    })

                                    console.log('timeRemaining', timeRemaining);
                                
                                }, 1000 * 1)

                            }

                        }*/

              state.life[keyLife] = _.data;
            }
          }
        })
        .catch((err) => {
          console.log(err);
          state.err = err;

          if (err.response && err.response.status == 403) {
            router.push("/login");
            return;
          }
        });
    },

    getQuestion: async function (state) {
      const instance = axios.create();

      if (state.play.sessionId == "") {
        const chars =
          "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        const now = Date.now();
        const length = 16;
        let result = "";

        for (let i = 0; i < length; i++) {
          result += chars.charAt(Math.floor(Math.random() * chars.length));
        }

        state.play.dateInit = now;
        state.play.sessionId = state.username + "." + now + "." + result;
      }

      await instance
        .post(
          state.config.server + `/game/v1/getQuestion`,
          qs.stringify({
            dateInit: state.play.dateInit,
            helpers: JSON.stringify(state.play.helpers),
            level: state.play.currentLevel,
            sessionId: state.play.sessionId,
            theme: state.theme,
            username: state.username,
          }),
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(async (_) => {
          if (_.status == 403) {
            router.push("/login");
            return;
          }

          if (_.data == null || _.data.length == 0) {
            alertComponent({
              header: "😥 DESCULPE!",
              message: "Não há perguntas suficientes para continuar.",
            });
            return false;
          }

          if (_.data) {
            _.data.options.forEach((e: any, i: number) => {
              _.data.options[i]["color"] = "primary";
            });

            state.play.currentQuestion = _.data;
            state.play.timeRemaining = 30;

            router.push("/play");
          }
        })
        .catch((err) => {
          state.err = err;

          if (err.response && err.response.status == 403) {
            router.push("/login");
            return;
          }
        });
    },

    getRanking: async function (state, payload) {
      const instance = axios.create();
      const { theme, timeInterval } = payload;

      const query: { theme?: string; timeInterval?: number } = {};

      if (theme) query.theme = theme;
      if (timeInterval) query.timeInterval = timeInterval;

      await instance
        .post(
          state.config.server + `/game/v1/getRanking`,
          qs.stringify(query),
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(async (_) => {
          if (_.status == 403) {
            router.push("/login");
            return;
          }

          if (_.data) {
            console.log(_.data);
            state.ranking = _.data;
          }
        })
        .catch((err) => {
          console.log(err);
          state.err = err;

          if (err.response && err.response.status == 403) {
            router.push("/login");
            return;
          }
        });
    },

    getThemes: async function (state, commit) {
      const instance = axios.create();

      await instance
        .post(
          state.config.server + `/game/v1/getThemes`,
          qs.stringify({
            username: state.username,
          }),
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then(async (_) => {
          if (_.status == 403) {
            router.push("/login");
            return;
          }

          if (_.data) {
            commit(_.data);
            state.themes = _.data;
          }
        })
        .catch((err) => {
          console.log(err);

          commit({});
          state.err = err;

          if (err.response && err.response.status == 403) {
            router.push("/login");
            return;
          }
        });
    },

    logIn: async (state, credentials) => {
      const { nickname } = credentials;

      state.loggingin = true;

      await axios
        .post(
          state.config.server + `/auth/v1/login`,
          qs.stringify({
            nickname,
          })
        )
        .then(async (_) => {
          if (_.data.auth) {
            state.token = _.data.token;
            state.username = _.data.user;

            if (typeof _.data.nickname != "undefined" && _.data.nickname) {
              state.nickname = _.data.nickname;
            }

            state.nickname = _.data.nickname;
            router.push("/");

            return;
          } else if (_.data.error) {
            alertComponent({
              header: "😥 ERRO!",
              message: "Por favor, verifique seu nome de usuário e senha.",
            });
          }
        })
        .catch((err) => {
          state.err = err;

          if (err.response && err.response.status == 400) {
            alertComponent({
              header: "Apelido em uso",
              message:
                "Este apelido já está em uso a partir de outro IP. Por favor, tente novamente após 1 hora ou escolha outro apelido.",
            });
          } else if (err.response && err.response.status == 403) {
            state.token = "";
            state.nickname = "";

            router.push("/login");
          }

          return;
        })
        .finally(() => (state.loggingin = false));
    },

    logOut: async (state) => {
      await axios
        .post(state.config.server + `/auth/v1/logout`, qs.stringify({}), {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        })
        .finally(() => {
          // Limpar o estado global
          state.token = "";
          state.nickname = "";
          state.username = "";

          // Redirecionar para a página de login ou início
          window.location.href =
            window.location.protocol + "//" + window.location.host;
        });
    },

    resetLevel: async (state) => {
      state.play.currentLevel = 0;
      state.play.currentQuestion = {};
      state.play.helpers = {
        cards: 1,
        jumps: 3,
      };
      state.play.sessionId = "";
    },

    saveLevel: async (state, payload = {}) => {
      const instance = axios.create();

      await instance
        .post(
          state.config.server + `/game/v1/save`,
          qs.stringify({
            action: payload.action || null,
            finalValue: JSON.stringify(payload.finalValue || null),
            helpers: JSON.stringify(state.play.helpers || null),
            level: state.play.currentLevel,
            sessionId: state.play.sessionId,
          }),
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
        .then((_) => {
          if (_.status == 403) {
            router.push("/login");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          state.err = err;

          if (err.response && err.response.status == 403) {
            router.push("/login");
            return;
          }
        });
    },

    useTime: (state, payload = {}) => {
      const decrement = payload.decrement || 1;

      if (state.play.timeRemaining - decrement >= 0) {
        state.play.timeRemaining -= decrement;
      }
    },
  },
});
