import { alertController } from '@ionic/vue';

export async function alertComponent (payload: any) : Promise<void> {

    const {header, message} = payload;

    const alert = await alertController.create({
        header,
        message,
        buttons: [
          {
            text: 'OK',
            role: 'confirm',
          },
        ],
      });

      await alert.present();

  }