<template>
  <ion-app>
    
    <ion-split-pane content-id="main-content" disabled>
      <ion-menu content-id="main-content" :disabled="!isLogged" type="overlay">
        <ion-content>
          <ion-list id="inbox-list">
            <ion-list-header>Menu Principal</ion-list-header>
            <ion-note>😎 {{ getCurrentNickname}}</ion-note>
            <ion-menu-toggle v-for="(p, i) in appPages" :key="i">
              <ion-item v-if="isLogged" @click="selectedIndex = i" router-direction="root" :router-link="p.url" lines="none" class="hydrated" :class="{ selected: selectedIndex === i }">
                <ion-icon slot="start" :ios="p.iosIcon" :md="p.mdIcon"></ion-icon>
                <ion-label>{{ p.title }}</ion-label>
              </ion-item>
            </ion-menu-toggle>
          </ion-list>
        </ion-content>
      </ion-menu>
      <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
    
  </ion-app>
</template>

<script lang="ts">

import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle, IonNote, IonRouterOutlet, IonSplitPane } from '@ionic/vue';
import { defineComponent, ref } from 'vue';
import { useRoute } from 'vue-router';
import { Store } from '@/store';
import { happy, logOut, refresh, trophy } from 'ionicons/icons';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel, 
    IonList, 
    IonListHeader, 
    IonMenu,
    IonMenuToggle,
    IonNote, 
    IonRouterOutlet, 
    IonSplitPane
  },
  computed: {

    'getCurrentNickname': () => Store.getters.getCurrentNickname,
    'isLogged': () => Store.getters.isLogged,
    'isOpenNicknameModal': () => Store.getters.isOpenNicknameModal || false,
    'selectedIndex': () => ref(0),
    'path': () => window.location.pathname,
    'route': () => useRoute()

  },
  data: () => { return {

    'appPages': [{
      title: 'Game',
      url: '/dashboard',
      iosIcon: happy,
      mdIcon: happy
    }, {
      title: 'Ranking',
      url: '/ranking',
      iosIcon: trophy,
      mdIcon: trophy
    }, {
      title: 'Reset Game',
      url: '/reset',
      iosIcon: refresh,
      mdIcon: refresh
    }, {
      title: 'Sair',
      url: '/logout',
      iosIcon: logOut,
      mdIcon: logOut
    }],

    'nickname': ''

  }},
  methods: {

    'openNicknameModal': () => Store.dispatch('changeNicknameModal', {'action': 'open'}), 
    
    'setNickname': async function () {

      if (this.getCurrentNickname.length > 0 && this.nickname.length == 0 || this.getCurrentNickname === this.nickname) {

        Store.dispatch('changeNicknameModal', {'action': 'close'});
        return;

      }

      if ((await Store.dispatch('setNickname', {'nickname': this.nickname}))) {

        Store.dispatch('changeNicknameModal', {'action': 'close'});
        return;

      }

    },

  },
  mounted: function() {

    const html = document.documentElement;
    html.setAttribute('lang', 'pt-BR');

    if (this.path !== undefined) {

      this.selectedIndex.value = this.appPages.findIndex(page => page.title.toLowerCase() == this.path.toLowerCase());
    
    }
    
  },

  watch: {

  '$route' () {

    return;

  }

}
});

</script>

<style scoped>
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}

ion-menu.md ion-list {
  padding: 20px 0;
}

ion-menu.md ion-note {
  margin: 10px 0;
}

ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}

ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;

  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;

  margin-bottom: 18px;

  color: #757575;

  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}

ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.14);
}

ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;

  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
</style>